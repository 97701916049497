import React from 'react'
import Banner from '../../components/Banner/Banner'
import MechanikaList from '../../components/MenuList/MechanikaOsobowaList'
import './MechanikaOsobowa.css'
import { Helmet } from'react-helmet';
import mechanikaosobowe from '../../assets/images/mechanika-osobowe-bg.webp'

export default function MechanikaOsobowa() {
  return (
    <div>
      <Helmet>
        <title>Mechanika samochodów osobowych, SUV i dostawczych - Euromaster Bednarscy</title>
      </Helmet>
      <Banner image={mechanikaosobowe} title='Mechanika samochodów osobowych, SUV i dostawczych' buttonText='ZAREZERWUJ TERMIN' buttonLink='https://www.euromaster.pl/wizyta?fitter=6571a5bbf4dd161ff5b9c50f'/>
      <MechanikaList />
    </div>
  )
}
