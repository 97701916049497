import React from 'react'
import Banner from '../../components/Banner/Banner'
import mechanikaciezarowa from '../../assets/images/mechanika-ciezarowa-bg.webp'
import './MechanikaCiezarowa.css'
import { Helmet } from'react-helmet';
import MechanikaCiezarowaList from '../../components/MenuList/MechanikaCiezarowaList'

export default function MechanikaCiezarowa() {
  return (
    <>
      <Helmet>
        <title>Mechanika samochodów ciężarowych i naczep - Euromaster Bednarscy</title>
      </Helmet>
      <Banner image={mechanikaciezarowa} title='Mechanika samochodów ciężarowych' buttonText='ZAREZERWUJ TERMIN' buttonLink='https://www.euromaster.pl/wizyta?fitter=6571a5bbf4dd161ff5b9c50f'/>
      <MechanikaCiezarowaList />
    </>
  )
}
