import React from 'react'
import Banner from '../../components/Banner/Banner'
import bieznikowaniebg from '../../assets/images/bieznikowanie-bg.webp'
import odkup from '../../assets/images/odkup.webp'
import { Helmet } from 'react-helmet';
import './OponyCiezarowe.css'

export default function Bieznikowanie() {
  return (
    <>
    <Helmet>
      <title>Serwis opon Michelin Remix - bieżnikowe na gorąco - Euromaster Bednarscy</title>
    </Helmet>
    <Banner image={bieznikowaniebg} title="Opony bieżnikowane na gorąco​" buttonText="ZAREZERWUJ TERMIN" buttonLink="https://www.euromaster.pl/wizyta?fitter=6571a5bbf4dd161ff5b9c50f" />

    <div className='onepage-content md:px-20 px-8 py-10'>
     
     <h1>​Opony Michelin Remix - bieżnikowane jak nowe</h1>
      
      <p>​Marka Michelin od lat jest uznawana za jednego z czołowych graczy na światowym rynku oponiarskim, znana z nowatorskich rozwiązań i innowacyjnych projektów. W swojej ofercie ma również linię opon bieżnikowanych, które zdobywają coraz większą popularność. Opony Michelin Remix zdobyły oddane grono fanów na całym świecie.</p>
      
      <p>Opony Michelin Remix charakteryzują się imponującymi właściwościami, przekonując nawet największych przeciwników opon bieżnikowanych. Ich jakość jest bardzo zbliżona do nowych, oryginalnych modeli marki Michelin, a potencjał przebiegu jest porównywalny, co sprawia, że służą kierowcom przez długie lata. Opony te mają bieżniki identyczne jak w nowych produktach Michelin, co zapewnia ich wysoką jakość i wydajność.</p>
      
      <p>Opony bieżnikowane cieszą się dużym uznaniem wśród kierowców, oferując rewelacyjne parametry i właściwości. Michelin sprostał wymaganiom związanym z produkcją tych opon, tworząc produkt spełniający oczekiwania nawet najbardziej wymagających fanów motoryzacji.</p>

      <h2>​Usługa bieżnikowania oferuje dwie opcje:</h2>

      <ul>
        <li>
        ​Klient może dostarczyć własny karkas, płacąc jedynie za samą usługę bieżnikowania.
        </li>
        <li>
        Alternatywnie, można zakupić gotową, bieżnikowaną oponę.
        </li>
      </ul>

      <h2>Kondycja karkasu:</h2>

      <p>Opona musi być w dobrym stanie, bez uszkodzeń bocznych, licznych przebicia, otarć, korozji warstw opasania i deformacji spowodowanych zaniżonym ciśnieniem. Opony premium częściej nadają się do bieżnikowania na gorąco, a inne opony – na zimno. Pogłębianie opon należy zlecać autoryzowanym serwisom.</p>

      <h2>Montaż opon bieżnikowanych:</h2>

      <p>​Najczęściej montuje się je na osiach napędowych lub wleczonych ze względu na mniejsze obciążenia. Producent opon może mieć dodatkowe zalecenia, np. Michelin nie zaleca montażu opon Michelin Remix na pierwszej osi prowadzącej.</p>

      <div className='odkup md:px-40 px-5 text-center items-center flex flex-col'>

        <h1 className='text-main-green pt-5'>ODKUP OPON ZUŻYTYCH</h1>
        <h2 className=''>
          Odkupujemy również opony zużyte na ​oryginalnym karkasie Michelin
        </h2>
        <p>​Odkupując zużyte opony przeznaczone do bieżnikowania, pomagamy zmniejszyć wpływ na środowisko i maksymalizować zyski, nie rezygnując z bezpieczeństwa.</p>

        <img src={odkup} alt='odkup opony' className='h-64'></img>
      
      </div>
    </div>
    </>
  )
}