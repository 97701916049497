import React from 'react'
import Banner from '../../components/Banner/Banner'
import OponyList from '../../components/MenuList/OponyCiezaroweList'
import './OponyCiezarowe.css'
import { Helmet } from'react-helmet';
import oponyciezarowe from '../../assets/images/opony-ciezarowe-bg.webp'

export default function OponyCiezarowe() {
  return (
    <>
      <Helmet>
        <title>Opony do samochodów ciężarowych i naczep, dla budownictwa i rolnictwa - Euromaster Bednarscy</title>
      </Helmet>
      <Banner image={oponyciezarowe} title='​Opony do samochodów ciężarowych i naczep' buttonText='ZAREZERWUJ TERMIN' buttonLink='https://www.euromaster.pl/wizyta?fitter=6571a5bbf4dd161ff5b9c50f'/>
      <OponyList />
    </>
  )
}
